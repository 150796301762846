






















































































































































// Vue
import Vue from "vue";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Models
import { IJob } from "@/core/_models/job.model";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// moment.js
import moment from "moment";
// Services
import JobsService from "../dispatch/jobs/Jobs.service";
// Firebase
import "firebase/auth";
import "firebase/firestore";
// Components
import JobCard from "@/core/components/content/JobCard.vue";

export default Vue.extend({
  name: "JobSearch",
  components: { JobCard },

  data() {
    return {
      isLoading: false,

      // Search Fields
      searchCustomerName: "",
      searchJobSite: "",

      // Checkbox search flags
      isCompleted: true,
      isNotCompleted: false,

      selectedJob: undefined as IJob | undefined,

      // Filtered Jobs data from db
      filteredJobsData: [] as IJob[]
    };
  },

  mixins: [validationMixin],

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("customers", {
      fetchCustomers: "fetchCustomers",
      pushCustomers: "pushCustomers",
      setCustomer: "setCustomer",
      removeCustomer: "removeCustomer"
    }),

    searchJobs(): void {
      this.selectedJob = undefined;
      this.isLoading = true;

      JobsService.searchJobs(
        this.currentUser.clientId,
        this.isCompleted,
        this.searchCustomerName.toLowerCase().trim(),
        this.searchJobSite.toLowerCase().trim()
      ).then((data: IJob[]) => {
        if (!data) return;
        this.filteredJobsData = data;
        this.isLoading = false;
      });
    },

    scrollToTop(): void {
      window.scrollTo(0, 0);
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      activeJobs: "activeJobs",
      updateDashboard: "updateDashboard"
    })
  },

  filters: {
    // moment(date: any) {
    //   return moment(date.toDate()).format("MMMM Do YYYY, h:mm A");
    // }

    moment: function (date: any) {
      try {
        return moment(date.toDate()).format("MMMM Do, YYYY");
      } catch {
        try {
          return moment(date).format("MMMM Do, YYYY");
        } catch {
          return "";
        }
      }
    },

    momentDateTime: function (date: any) {
      try {
        return moment(date.toDate()).format("dddd, MMMM Do YYYY");
      } catch {
        try {
          return moment(date).format("dddd, MMMM Do YYYY");
        } catch {
          return "";
        }
      }
    },

    momentTime: function (date: any) {
      try {
        return moment(date.toDate()).format("h:mm A");
      } catch {
        try {
          return moment(date).format("h:mm A");
        } catch {
          return "";
        }
      }
    }
  }
});
